<template>
  <div id="inicio">
    <div class="hero-wrap ftco-degree-bg">
      <div class="container contentCity">
        <div
          class="row no-gutters slider-text justify-content-start align-items-center justify-content-center"
        >
          <div class="col-lg-10 ftco-animate">
            <div class="text w-100 text-center mb-md-5 pb-md-5">
              <h1 class="mb-4">
                <span>
                  <VScrollin :speed="45">Connecting&nbsp;</VScrollin>
                </span>

                <span>
                  <VScrollin :speed="45">Businesses&nbsp;</VScrollin>
                </span>

                <span>
                  <VScrollin :speed="45">& People</VScrollin>
                </span>
                
              </h1>

              <p style="font-size: 18px">
                Desarrolla e implementa acciones para llevar a tu empresa al
                siguiente nivel.
              </p>

              <!-- <a href="https://vimeo.com/45830194" class="icon-wrap popup-vimeo d-flex align-items-center mt-4 justify-content-center">
              <div class="icon d-flex align-items-center justify-content-center">
              <b-icon icon="play-circle" style="color: #FFFFFF;"></b-icon>
              </div>
              </a> -->

              <a
                href="#pricing"
                class="icon-wrap d-flex align-items-center mt-4 justify-content-center"
              >
                <div
                  class="icon d-flex align-items-center justify-content-center"
                >
                  <b-icon
                    icon="chevron-double-down"
                    style="color: #000000"
                  ></b-icon>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="ftco-section ftco-no-pt bg-light">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-md-12 featured-top">
            <div class="row d-flex justify-content-center no-gutters">
              <div class="col-md-10 d-flex align-items-center">
                <div class="services-wrap rounded-right w-100 text-center">
                  <h3 class="heading-section mb-4 text-center">
                    Nuestro servicio de soporte abarca:
                  </h3>

                  <br />

                  <div class="row d-flex mb-4">
                    <div
                      class="col-md-4 d-flex align-self-stretch ftco-animate"
                    >
                      <div class="services w-100 text-center">
                        <div
                          class="icon d-flex align-items-center justify-content-center"
                        >
                          <b-icon icon="chat-dots-fill"></b-icon>
                        </div>

                        <div class="text w-100">
                          <h3 class="heading mb-2">Gente especializada</h3>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-md-4 d-flex align-self-stretch ftco-animate"
                    >
                      <div class="services w-100 text-center">
                        <div
                          class="icon d-flex align-items-center justify-content-center"
                        >
                          <b-icon icon="clock-fill"></b-icon>
                        </div>

                        <div class="text w-100">
                          <h3 class="heading mb-2">Las 24 hrs del día</h3>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-md-4 d-flex align-self-stretch ftco-animate"
                    >
                      <div class="services w-100 text-center">
                        <div
                          class="icon d-flex align-items-center justify-content-center"
                        >
                          <b-icon icon="calendar2-check-fill"></b-icon>
                        </div>

                        <div class="text w-100">
                          <h3 class="heading mb-2">Los 365 días del año</h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p>
                    <a href="#contact" class="btn btn-primary mt-4 py-2 px-4">
                      CONTRATA
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import VScrollin from "vue-scrollin";

export default {
  name: "Hero",

  components: {
    VScrollin,
  },
};
</script>

<style scoped>
.ftco-section {
  padding: 6em 0;
  position: relative;

  background: #000000 !important;
}

.ftco-no-pt {
  padding-top: 0;
}

.ftco-degree-bg {
  position: relative;
}

.ftco-degree-bg:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -50px;
  overflow: visible;
  width: 100%;
  height: 120px;
  z-index: 0;
  -webkit-transform: skewY(-4deg);
  -moz-transform: skewY(-4deg);
  -ms-transform: skewY(-4deg);
  -o-transform: skewY(-4deg);
  transform: skewY(-4deg);
  background-color: #000000;
}

.hero-wrap {
  width: 100%;
  height: 850px;
  position: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-image: url(../../assets/img/bg/panal_bg.jpg);
}

.hero-wrap .contentCity {
  position: inherit;
  background-size: 95% 90%;
  background-repeat: no-repeat;
  background-position: top center;
  background-image: url(../../assets/img/illustrations/beetelecom-home.png);
}

.hero-wrap .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  opacity: 0.3;
  background: #000000;
  height: 850px;
}

.hero-wrap.hero-wrap-2 {
  height: 600px !important;
  position: relative;
}

.hero-wrap.hero-wrap-2 .overlay {
  width: 100%;
  opacity: 0.3;
  height: 600px;
}

.hero-wrap.hero-wrap-2 .slider-text {
  height: 600px !important;
}

.slider-text {
  height: 850px;
}

.slider-text h1 {
  display: inline-flex;
}

.slider-text h1 span {
  font-size: 50px;
  color: #fecd00;
  line-height: 1.1;
  font-weight: 700;
  display: inline;
}

.slider-text h1 small {
  font-size: 30px;
  color: #ffffff;
  line-height: 1.1;
  font-weight: 400;
}

.slider-text .icon-wrap {
  text-decoration: none;
}

.slider-text .icon-wrap .icon {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background: #fecd00;
}

.slider-text .icon-wrap .icon svg {
  color: #ffffff;
  font-size: 2.5rem;
}

.slider-text .icon-wrap .heading-title {
  position: relative;
}

.slider-text .icon-wrap .heading-title:after {
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translateY(-50%);
  content: "";
  width: 30px;
  height: 2px;
}

.slider-text .icon-wrap .heading-title span {
  color: #ffffff;
  padding-left: 10px;
}

.slider-text p {
  line-height: 1.5;
  color: rgba(255, 255, 255, 1);
}

.featured-top {
  margin-top: -250px;
}

.services-wrap {
  padding: 4em 3em;
  background: #fecd00;

  -webkit-box-shadow: 0px 5px 12px -1px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 5px 12px -1px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 12px -1px rgba(0, 0, 0, 0.03);
}

.services-wrap .heading-section {
  font-size: 24px;
  font-weight: 600;
}

.services .icon {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: solid 5px #fecd00;
  border-left-color: #000000;
  border-right-color: #000000;
  background: #000000;
  margin: 0 auto;
  margin-bottom: 20px;
}

.services .icon:hover {
  background: #fecd00;
  border: solid 5px #fecd00;
  border-left-color: #000000;
  border-right-color: #000000;
}

.services .icon:hover > svg {
  color: #000000;
}

.services .icon svg {
  font-size: 40px;
  color: #fecd00;
}

.services h3 {
  font-size: 20px;
  font-weight: 600;
}

.services.services-2 .icon {
  width: 110px;
  height: 110px;
  background: #fecd00;
  border: none;
}

.services.services-2 .icon span {
  color: #ffffff;
}

.services-wrap a {
  background: transparent;
  border: 2px solid #000000;
  border-radius: 25px;
  color: #000000;
}

.services-wrap a:hover {
  background: #000000;
  color: #fecd00;
}

@media (max-width: 991px) {
  .slider-text h1 {
    display: block;
  }
}
</style>
