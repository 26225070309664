<template>
  <section class="map-section section-bg1 section-padding30 fix">
    <particles-bg
      type="cobweb"
      :canvas="{ backgroundColor: '#fecd00' }"
      :bg="true"
    />

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 col-lg-9 col-md-10">
          <div class="section-tittle text-center mb-90">
            <h2>Mapa de cobertura</h2>

            <p>
              Orgullosamente conectando a nuestra República Mexicana para que
              cada vez más empresas se sumen a la conectividad de Bee Telecom.
            </p>
            <p>Te invitamos a consultar nuestro mapa de cobertura:</p>
          </div>
        </div>
      </div>

      <l-map style="height: 400px" :zoom="zoom" :center="center">
        <l-tile-layer :url="url" :attribution="attribution" />

        <v-geosearch :options="geosearchOptions" />

        <Vue2LeafletLocatecontrol />

        <l-circle
          :lat-lng="circle.center"
          :radius="circle.radius"
          :color="circle.color"
        />
      </l-map>

      <div class="row justify-content-center mt-2">
        <div class="col-xl-8 col-lg-9 col-md-10">
          <div class="section-subtitle text-center">
            <small>
              * La prestación del servicio dentro de la cobertura está sujeta a
              la factibilidad técnica.
            </small>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { LMap, LTileLayer, LCircle } from "vue2-leaflet";
import { ParticlesBg } from "particles-bg-vue";

import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";

import Vue2LeafletLocatecontrol from "vue2-leaflet-locatecontrol";

export default {
  name: "Map",

  components: {
    LMap,
    LTileLayer,
    LCircle,
    ParticlesBg,
    VGeosearch,
    Vue2LeafletLocatecontrol,
  },

  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 13,
      center: [19.075206, -98.333909],
      circle: {
        center: [19.075206, -98.333909],
        radius: 3500,
        color: "#fecd00",
        fillColor: "#fecd00",
        fillOpacity: 1,
      },

      // Important part Here
      geosearchOptions: {
        provider: new OpenStreetMapProvider(),
      },
    };
  },
};
</script>

<style scoped>
@import "~leaflet/dist/leaflet.css";
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";

.section-padding30 {
  padding-top: 140px;
  padding-bottom: 140px;
}

.map-section {
  position: relative;
}

.map-section .section-tittle h2 {
  font-size: 44px;
  display: block;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 22px;
  color: #ffffff;
}

.map-section .section-tittle p {
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
}

.map-section .section-subtitle small {
  color: #ffffff;
}

.map-section .section-tittle p > span {
  color: #140c40;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 22px;
  text-transform: capitalize;
  display: inline-block;
  letter-spacing: 0.05em;
}
</style>
