<template>
  <section class="contact-section" id="contacto">
    <div class="container">
      <div class="row contact p-5">
        <div class="col-12">
          <h2 class="contact-title">Déjanos tus comentarios</h2>
        </div>

        <div class="col-12 col-lg-7">
          <form
            class="form-contact contact_form"
            id="contactForm"
            novalidate="novalidate"
            ref="form"
            @submit.prevent="sendEmail"
          >
            <b-row>
              <b-col sm="6" xs="12">
                <div class="form-group">
                  <b-form-input
                    v-model="name"
                    name="name"
                    id="name"
                    class="form-control"
                    placeholder="* Nombre completo"
                  ></b-form-input>
                </div>
              </b-col>

              <b-col sm="6" xs="12">
                <div class="form-group">
                  <b-form-input
                    v-model="company"
                    name="company"
                    id="company"
                    class="form-control"
                    placeholder="* Nombre de tu compañía"
                  ></b-form-input>
                </div>
              </b-col>

              <b-col sm="6" xs="12">
                <div class="form-group">
                  <b-form-input
                    v-model="phone"
                    name="phone"
                    id="phone"
                    class="form-control"
                    placeholder="* Número telefónico"
                    type="number"
                    @keypress="isNumber($event)"
                    max="10"
                  ></b-form-input>
                </div>
              </b-col>

              <b-col sm="6" xs="12">
                <div class="form-group">
                  <b-form-input
                    v-model="email"
                    id="email"
                    name="email"
                    class="form-control"
                    placeholder="* Correo electrónico"
                    type="email"
                  ></b-form-input>
                </div>
              </b-col>

              <b-col md="12">
                <div class="form-group">
                  <b-form-select
                    v-model="service"
                    name="service"
                    id="service"
                    class="form-control"
                    :options="options"
                  ></b-form-select>
                </div>
              </b-col>

              <b-col md="12">
                <div class="form-group">
                  <b-form-textarea
                    v-model="message"
                    name="message"
                    id="message"
                    placeholder="* Déjanos tus comentarios"
                    cols="30"
                    rows="9"
                    max-rows="9"
                  ></b-form-textarea>
                </div>
              </b-col>
            </b-row>

            <b-alert
              :show="dismissCountDown"
              fade
              variant="danger"
              @dismiss-count-down="countDownChanged"
            >
              {{ messageValidation }}
            </b-alert>

            <b-row class="d-flex justify-content-center">
              <b-col md="5" class="text-center">
                <b-button
                  variant="default"
                  type="submit"
                  class="button-contactForm px-4 py-2"
                >
                  Enviar
                </b-button>
              </b-col>
            </b-row>
          </form>
        </div>

        <div class="col-lg-4 offset-lg-1 mt-5 mt-md-0">
          <div class="media contact-info p-1">
            <div
              class="contact-info__icon d-flex justify-content-center align-items-center p-2"
            >
              <b-icon icon="pin-map"></b-icon>
            </div>

            <div class="media-body">
              <h3>Calle 10 Norte 603 Oficina privada 4A</h3>
              <p>San Pedro Cholula, Puebla C.P. 72750</p>
            </div>
          </div>

          <div class="media contact-info p-1">
            <div
              class="contact-info__icon d-flex justify-content-center align-items-center p-2"
            >
              <b-icon icon="telephone-fill"></b-icon>
            </div>

            <div class="media-body">
              <h3>800 578 2323</h3>
              <p>Atención a clientes: Lunes a Viernes 9:00 a 18:00 hrs</p>
            </div>
          </div>

          <div class="media contact-info p-1">
            <div
              class="contact-info__icon d-flex justify-content-center align-items-center p-2"
            >
              <b-icon icon="mailbox"></b-icon>
            </div>

            <div class="media-body">
              <h3>contacto@beetelecom.mx</h3>
              <p>Envíanos tus dudas</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import emailjs from "@emailjs/browser";

export default {
  name: "Contact",

  data() {
    return {
      options: [
        {
          value: null,
          text: "* Selecciona tu plan",
          disabled: true,
        },
        {
          value: "BeeReady, Enlace de Internet Dedicado de 20/20 Mbps.",
          text: "Enlace de Internet Dedicado de 50/50 Mbps.",
        },
        {
          value: "BeeFast, Enlace de Internet Dedicado de 50/50 Mbps.",
          text: "Enlace de Internet Dedicado de 100/100 Mbps.",
        },
        {
          value: "BeeFull, Enlace de Internet Dedicado de 100/100 Mbps.",
          text: "Enlace de Internet Dedicado de 200/200 Mbps.",
        },
      ],

      message: "",
      name: "",
      company: "",
      email: "",
      phone: "",
      service: null,

      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,

      messageValidation:
        "Por favor, complete toda la información solicitada para continuar",
    };
  },

  methods: {
    sendEmail(e) {
      e.preventDefault();

      if (
        !this.message ||
        !this.name ||
        !this.company ||
        !this.email ||
        !this.phone ||
        !this.service
      ) {
        this.showAlert();

        return;
      }

      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      emailjs
        .sendForm(
          process.env.VUE_APP_SERVICE_MAIL,
          process.env.VUE_APP_TEMPLATE_MAIL,
          this.$refs.form,
          process.env.VUE_APP_PUBLIC_KEY_MAIL
        )
        .then(
          (result) => {
            console.log("200", result.text);

            Toast.fire({
              icon: "success",
              title: "Mensaje enviado",
            });

            // Reset form field
            this.name = "";
            this.company = "";
            this.email = "";
            this.phone = "";
            this.service = null;
            this.message = "";
          },
          (error) => {
            console.log("500", error.text);

            Toast.fire({
              icon: "error",
              title: "No se pudo enviar el mensaje",
            });
          }
        );
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.contact {
  background: rgba(0, 0, 0, 0.6);
}

.contact-section {
  background-image: url(../../assets/img/bg/panal_bg.jpg);
  background-repeat: no-repeat;
  background-attachment: unset;
  background-position: center center;
  background-size: cover;
  padding: 130px 0 100px;
}

.contact-section .contact-title {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #fecd00;
}

.contact-section .form-contact .form-group {
  margin-bottom: 30px;
}

.contact-section .form-contact .button-contactForm {
  background: #fecd00;
  color: #000000;
  font-size: 17.5px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;

  border-radius: 30px;
  border: solid 5px #fecd00;
  border-top-color: #000000;
  border-bottom-color: #000000;
}

.contact-section .form-contact .button-contactForm:hover {
  background: #000000;
  color: #fecd00;
}

.contact-section .form-contact .form-group .form-control {
  border: 1px solid #fecd00;
  border-radius: 0px;
  height: 48px;
  padding-left: 18px;
  font-size: 15px;
  background: transparent;
  color: #fecd00;
  font-weight: 600;
}

.contact-section .form-contact .form-group .form-control:focus {
  outline: 0;
  box-shadow: none;
}

.contact-section .form-contact .form-group .form-control::placeholder {
  font-weight: 600;
  color: #fecd00;
}

.contact-section .form-contact .form-group textarea {
  border-radius: 0px;
  height: 100% !important;
}

.contact-info__icon {
  background: #fecd00;
  border-radius: 50px;

  height: 70px !important;
  width: 70px !important;

  border: solid 5px #fecd00;
  border-top-color: #000000;
  border-bottom-color: #000000;

  margin-bottom: 10px;
}

.contact-info__icon:hover {
  background: #000000;
}

.contact-info__icon:hover > svg {
  color: #fecd00;
}

.contact-info__icon svg {
  color: #000000;
  font-size: 1.5rem;
}

.contact-info .media-body h3 {
  color: #fecd00;
  font-size: 1.25rem;
}

.contact-info .media-body p {
  color: #ffffff;
  font-size: 1rem;
}
</style>
