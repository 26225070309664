<template>
  <div id="nosotros">
    <section class="ftco-section ftco-about">
      <div class="container">
        <b-row class="no-gutters">
          <b-col
            md="6"
            class="p-1 img img-2 d-flex justify-content-center align-items-center d-none d-md-block"
          ></b-col>

          <b-col md="6" class="wrap-about ftco-animate">
            <div class="heading-section heading-section-white p-3 p-md-5">
              <span class="subheading">Nosotros</span>
              <h2 class="mb-4">¿Quiénes somos?</h2>

              <b-row class="d-flex justify-content-center">
                <b-col sm="12" md="9">
                  <p><b>Misión</b></p>

                  <p>
                    Mejorar la conectividad con la sociedad a través de
                    proyectos que abarcan desde la infraestructura en espacios
                    interiores o exteriores hasta un enlace de internet de alta
                    calidad, donde nuestros clientes se encuentren satisfechos
                    con los resultados.
                  </p>
                </b-col>
              </b-row>

              <b-row class="d-flex justify-content-center">
                <b-col sm="12" md="9">
                  <p><b>Visión</b></p>

                  <p>
                    Incrementar la participación de Bee Telecom y su
                    consolidación en los mercados en donde opera con el objetivo
                    de expandirse y lograr ser un referente a nivel nacional
                    como empresa líder en conexiones de telecomunicaciones.
                  </p>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </section>

    <section class="ask-questions section-bg1 section-padding30 fix">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-9 col-md-10">
            <div class="section-tittle text-center mb-90">
              <h2>Nuestros valores</h2>
              <p>
                Los valores definen a una empresa y sirven como eje para su
                crecimiento, y los valores que nos definen a nosotros son:
              </p>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-lg-6 col-md-6">
            <div class="single-question d-flex mb-50">
              <span> 1.</span>

              <div class="pera">
                <h2>&nbsp; Tecnología Vanguardista</h2>
                <p>
                  Desarrollamos servicios que brindan soluciones orientadas a la
                  Tecnología de Punta para lograr la satisfacción de nuestros
                  clientes.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6">
            <div class="single-question d-flex mb-50">
              <span> 2.</span>

              <div class="pera">
                <h2>&nbsp; Compromiso</h2>
                <p>
                  Cada proyecto lo tomamos como nuestro, planificamos y
                  ejecutamos nuestro plan de acción para lograr los mejores
                  resultados.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6">
            <div class="single-question d-flex mb-50">
              <span> 3.</span>

              <div class="pera">
                <h2>&nbsp; TeamWork</h2>
                <p>
                  Todos los involucrados trabajamos bajo el mismo objetivo para
                  potencializar cada estrategia planteada o sugerida.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6">
            <div class="single-question d-flex mb-50">
              <span> 4.</span>

              <div class="pera">
                <h2>&nbsp; Calidad</h2>
                <p>
                  Buscamos cumplir las especificaciones que requieres para tu
                  proyecto y así satisfacer las necesidades que el
                  proyecto-cliente-Bee Telecom requiere.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "About Us",
};
</script>

<style scoped>
.ftco-section {
  padding: 6em 0;
  position: relative;

  background: #000000 !important;
}

.ftco-about {
  position: relative;
  z-index: 0;
}

.ftco-about:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: #fecd00;
  z-index: -2;
  width: 63%;
}

.img {
  background-image: url(../../assets/img/illustrations/call-to-action.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.heading-section .subheading {
  font-size: 18px;
  display: block;
  margin-bottom: 5px;
  color: #fecd00;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.heading-section h2 {
  font-size: 40px;
  font-weight: 600;
}

.heading-section.heading-section-white .subheading {
  color: rgba(255, 255, 255, 0.9);
}

.heading-section.heading-section-white h2 {
  font-size: 40px;
  color: #000000;
}

.heading-section.heading-section-white p {
  color: rgba(0, 0, 0, 0.9);
}

.heading-section.heading-section-white p b {
  color: #000000;
  font-weight: 600;
  font-size: 22.5px;
}

.section-padding30 {
  padding-top: 195px;
  padding-bottom: 100px;
}

.ask-questions {
  position: relative;
}

.ask-questions .section-tittle h2 {
  font-size: 44px;
  display: block;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 22px;
  color: #61e8cf;
}

.ask-questions .section-tittle p {
  color: #fecd00;
}

.ask-questions .section-tittle p > span {
  color: #140c40;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 22px;
  text-transform: capitalize;
  display: inline-block;
  letter-spacing: 0.05em;
}

.ask-questions .single-question span {
  color: #ffffff;
  font-size: 20px;
}

.ask-questions .single-question .pera {
  padding-left: 28px;
  line-height: 1.5;
}

.ask-questions .single-question .pera h2 {
  color: #fecd00;
  font-size: 30px;
  margin-bottom: 21px;
  line-height: 1.5;
}

.ask-questions .single-question .pera p {
  color: #ffffff;
  font-size: 15px;
}

@media (max-width: 767.98px) {
  .ftco-about:after {
    width: 100%;
  }

  .img-2 {
    height: 600px;
    margin-bottom: 40px;
  }

  .heading-section h2 {
    font-size: 28px;
  }
}
</style>
